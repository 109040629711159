import React, { useState } from "react";
import { FaCartPlus, FaSignInAlt } from "react-icons/fa";
import { Link } from "gatsby";

import { StyledScheduleItem } from "styles/styles";
import { formatToMilitaryDate, getTotalDays } from "helpers";

const OthereventItem = ({ item, addToCart, cartState }) => {
  // console.log("ScheduleItem -> item", item);
  const [qty, setQty] = useState(1);

  const inCart =
    cartState && cartState.cart && cartState.cart.find(x => x.id === item.id);

  const handleQtyChange = e => {
    setQty(e.target.value);
  };

  const totalEnrolled =
    (item &&
      item.orders &&
      item.orders.reduce((acc, cur) => acc + cur.qty, 0)) ||
    0;

  const availableSeats = item.totalseats - totalEnrolled;

  return (
    <StyledScheduleItem>
      <div className="details">
        <h5>{item.title}</h5>
        <p>
          <b>Start Time:</b> {item.timestart}
        </p>

        {item.datestop && (
          <p>
            <b>End Date:</b> {formatToMilitaryDate(item.datestop)} <br />
            (This is a{" "}
            {getTotalDays(Date.parse(item.date), Date.parse(item.datestop))} day
            class)
          </p>
        )}

        {item.note && (
          <p className="mt-2" style={{ whiteSpace: "pre-line" }}>
            Note: {item.note}
          </p>
        )}
        {item.url && (
          <p className="mt-2">
            <a
              href={item.url}
              targed="_blank"
              rel="noreferrer"
              className="btn btn-primary"
            >
              Link
            </a>
          </p>
        )}
      </div>
      <div className="datetime">
        <span className="day">{item.day}</span>
        <span className="month">{item.month}</span>
        <span className="year">{item.year}</span>
      </div>
    </StyledScheduleItem>
  );
};
export default OthereventItem;
