import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import format from "date-fns/format";

// hooks and cart
import { cartContext } from "providers/CartProvider";

import Layout from "components/Layout";
import SEO from "components/Seo";
import OthereventItem from "components/OthereventItem";

import {
  MonthlyScheduleGroup,
  MonthlyScheduleList,
  MonthlyScheduleHeader,
} from "styles/styles";

const RequalificationsPage = () => {
  const [scheduleData, setScheduleData] = useState([]);
  const siteContext = useContext(cartContext);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${process.env.GATSBY_API_URL}/otherevents`,
          {
            validateStatus(status) {
              return status < 500; // Resolve only if the status code is less than 500
            },
          }
        );

        // console.log("Main -> response.data", response.data);
        setScheduleData(response.data);
      } catch (e) {
        siteContext.makeToast("Unable to fetch schedule data.", "error");
      }
    }

    fetchData();
  }, []);

  // const pgClasses = data.pgClasses.nodes ? data.pgClasses.nodes : [];
  // console.log("ClassSchedulePage -> classesInCategory", classesInCategory);

  const formattedClasses =
    scheduleData && scheduleData.length > 0
      ? scheduleData.map(cl => {
          // const { date, datestop, timestart, timeend, id__normalized } = cat;
          const { date } = cl;
          const rawDate = Date.parse(date);

          const year = format(rawDate, "yyyy");
          const month = format(rawDate, "MMM");
          const day = format(rawDate, "dd");

          return {
            year,
            month,
            day,
            ...cl,
          };
        })
      : [];

  const classesByMonth = formattedClasses.reduce((acc, curr) => {
    const theMonthNum = format(Date.parse(curr.date), "M");
    const theYear = curr.year;

    const matchItem = acc.find(
      x => x.year === theYear && x.monthNum === theMonthNum
    );

    // new month/year
    if (!matchItem) {
      const tempMonthObj = {
        monthNum: theMonthNum,
        monthAbbrev: curr.month,
        year: theYear,
        classes: [curr],
      };

      return acc.concat(tempMonthObj);
    }

    // otherwise, just add to an existing month/year item
    const newClasses = matchItem.classes.concat(curr);
    matchItem.classes = newClasses;

    return acc;
  }, []);

  const cartState = useContext(cartContext);

  const addToCart = (scheduleID, qty = 1) => {
    const attendeesArr = [];
    for (let z = 0; z < qty; z++) {
      attendeesArr.push({
        name: "",
        email: "",
      });
    }

    const newItem = {
      id: scheduleID,
      attendees: attendeesArr,
      qty,
    };

    cartState.updateProducts(newItem);
  };

  return (
    <Layout pageInfo={{ pageName: "requalifications" }}>
      <SEO title="Firearms Requalifications" />
      <section className="class-main-details">
        <Container>
          <Row>
            <Col>
              <h2>Firearms Requalifications</h2>

              <p className="font-weight-bold" style={{ fontSize: "20px" }}>
                Virginia Armed Security Officers:
              </p>

              <p style={{ fontStyle: "italic" }}>
                In order to renew you must take the 07R/08R/ Firearms
                Requalification course online on our website, ddsta.com. Your
                registration for this course includes the range fees. Once you
                have completed your online course, you must attend one of the
                below scheduled range dates.
              </p>

              <p className="font-weight-bold" style={{ fontSize: "20px" }}>
                LEOSA:
              </p>

              <p style={{ fontStyle: "italic" }}>
                In order to renew your LEOSA you can attend any of the below
                scheduled range dates and bring your ammunition.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="bluedark">
        <Container>
          <Row>
            <Col>
              <div className="schedule">
                <h3>Schedule: Requalifications</h3>

                {classesByMonth &&
                  classesByMonth.map((monthData, i) => {
                    if (monthData.classes.length > 0) {
                      return (
                        <MonthlyScheduleGroup key={`month-${i}`}>
                          <MonthlyScheduleHeader>
                            <h4 className="subhead">
                              {`${monthData.monthAbbrev} ${monthData.year}`}
                            </h4>
                          </MonthlyScheduleHeader>

                          <MonthlyScheduleList>
                            {scheduleData.length > 0 && monthData.classes ? (
                              monthData.classes.map((item, z) => (
                                <li key={z}>
                                  <OthereventItem
                                    item={item}
                                    addToCart={addToCart}
                                    cartState={cartState}
                                  />
                                </li>
                              ))
                            ) : (
                              <p>No classes currently scheduled.</p>
                            )}
                          </MonthlyScheduleList>
                        </MonthlyScheduleGroup>
                      );
                    }
                    return null;
                  })}

                {!scheduleData && (
                  <p>
                    No upcoming classes scheduled in this category. Check back
                    soon!
                  </p>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default RequalificationsPage;
